
@import "~bulma-dracula/src/bulma-dracula.scss";

@import '~bulma-extensions/bulma-checkradio/src/sass/index.sass';

$theme: #44475a;
$selected: #6272a4;
$text: #f8f8f2;

:root {
  --animate-duration: 1s;
  --animate-delay: 1s;
  --animate-repeat: 1;
}

// Animate CSS --start
.animated {
  animation-duration: var(--animate-duration);
  animation-fill-mode: both;
  animation-iteration-count: infinite;
}

@keyframes heartBeat {
  0% {
    transform: scale(1);
  }

  14% {
    transform: scale(1.3);
  }

  28% {
    transform: scale(1);
  }

  42% {
    transform: scale(1.3);
  }

  70% {
    transform: scale(1);
  }
}

.heartBeat {
  animation-name: heartBeat;
  animation-duration: calc(var(--animate-duration) * 1.3);
  animation-timing-function: ease-in-out;
}


// Animate CSS --end

.nav-tag {
  position: relative;

  .icon {
    height: 2.5rem;
    width: 2.5rem;
    background-color: $background;
    border-radius: 50%;
  }

  .counter {
    border-radius: 50%;
    position: absolute;
    top: 5px;
    right: 0px;

    @media (max-width: 768px) {
      margin-left: -2px;
    }
  }
}

.hero {
  background-color: $theme;
}

.navbar {
  background: $theme;

  &-item {
    background-color: $theme;
    color: $text !important;

    &:not(.nav-tag).is-active {
      background-color: $selected !important;
      color: $text !important;
    }

    &:not(.nav-tag):hover {
      background-color: $selected !important;
      color: $text !important;
    }

    &.nav-tag.is-active {
      background-color: $theme !important;
      color: $primary !important;
    }

    &.nav-tag:hover {
      background-color: $theme !important;
      color: $primary !important;
    }
  }

  .is--brand {
    &:hover {
      background-color: $theme !important;
    }
  }

  &-brand-logo {
    max-height: 3rem !important;
    background-color: $theme;
    border-radius: .25em;
    padding: .25em;
  }

  &-dropdown {
    background-color: $theme;

    .navbar-item {
      background-color: $theme;
    }
  }

  &-burger {
    background-color: $theme !important;
    border: 0;

    span {
      height: 2px;
      width: 22px;
      background-color: $primary;
    }
  }

  &-link {
    color: $text;

    figure {
      img {
        border-radius: 50%;
        max-height: 2em;
      }
    }

    &:hover {
      background-color: $theme;
    }
  }
}

.dropdown {
  &-trigger button {
    color: $text;
    background-color: $theme;
  }
  &-content {
    background-color: $theme;
  }
}

.message.is-dark {
  .message-body {
    background-color: lighten($theme, 5%);
  }
}

.breadcrumb {
  &:not(:last-child) {
    margin-bottom: 0;
  }
}

.icon-title {
  margin-right: .5em;
  height: 3rem !important;
  width: 3rem !important;
  background-color: $primary;
  color: $background;
  border-radius: .25rem;
}

.modal-card-body {
  background-color: $theme;
  color: $text;
}

@media screen and (min-width: 1088px) {

  .navbar-item.has-dropdown:hover .navbar-link,
  .navbar-item.has-dropdown.is-active .navbar-link {
    background-color: $theme;
  }

}

// navigation left area
.aside {
  background: $theme;
  padding: 2em .2em 0 1em;
  height: calc(100vh - 3rem);
  overflow: hidden;
  transition: 0.2s width ease-out;
  position: relative;
  z-index: 1;

  a {
    color: $text;
  }

  .menu-list {
    li {
      margin-top: .5em;

    }

    .has-children {
      max-height: 36px;
      overflow: hidden;
      transition: 0.6s max-height ease-out;
      position: relative;

      >a {
        position: relative;
      }

      >a::before {
        content: "\f107";
        font-family: "FontAwesome";
        transition: .2s ease-out transform;
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-45%);
        transform-origin: center;
        transition-duration: 0.3s;
      }
    }

    .has-children.open {
      max-height: 1000px;

      >a::before {
        transform: rotate(-180deg) translateY(40%);
      }
    }

    .icon,
    i {
      margin-right: 10px;
      margin-left: 5px;
    }
  }

  #sidebar-toggler {
    width: 100%;
    cursor: pointer;
    bottom: 0;
    position: absolute;
    background-color: #bd93f9;
    margin: 0 -1rem 0 -0.25rem;
    padding: 1rem 2rem;
    display: flex;
    justify-content: flex-end;

    .icon {
      transition: 0.3s linear transform;
    }

  }

  &.column.is-2.closed {
    width: 60px;

    .menu-label,
    .menu-text {
      display: none;
    }

    .has-children {
      >a::before {
        opacity: 0;
      }
    }

    #sidebar-toggler {
      justify-content: center;

      .icon {
        transform: rotate(180deg);
        margin-right: 8px;
      }
    }

  }
}

// main area
.main {
  padding: 2em 1em 0 1em;
}

.menu-label {
  color: $primary;
  margin-left: .5em;
  font-weight: bold;
}

.block {
  margin-top: .5em;
}

.ads-square {
  min-width: 250px;
  min-height: 100px;
}

// Form
.field-label {
  text-align: left;
}




















.sk-folding-cube {
  margin: 20px auto;
  width: 40px;
  height: 40px;
  position: relative;
  -webkit-transform: rotateZ(45deg);
  transform: rotateZ(45deg);
}

  .sk-folding-cube .sk-cube {
      float: left;
      width: 50%;
      height: 50%;
      position: relative;
      -webkit-transform: scale(1.1);
      -ms-transform: scale(1.1);
      transform: scale(1.1);
  }

      .sk-folding-cube .sk-cube:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: $text;
          -webkit-animation: sk-foldCubeAngle 2.4s infinite linear both;
          animation: sk-foldCubeAngle 2.4s infinite linear both;
          -webkit-transform-origin: 100% 100%;
          -ms-transform-origin: 100% 100%;
          transform-origin: 100% 100%;
      }

  .sk-folding-cube .sk-cube2 {
      -webkit-transform: scale(1.1) rotateZ(90deg);
      transform: scale(1.1) rotateZ(90deg);
  }

  .sk-folding-cube .sk-cube3 {
      -webkit-transform: scale(1.1) rotateZ(180deg);
      transform: scale(1.1) rotateZ(180deg);
  }

  .sk-folding-cube .sk-cube4 {
      -webkit-transform: scale(1.1) rotateZ(270deg);
      transform: scale(1.1) rotateZ(270deg);
  }

  .sk-folding-cube .sk-cube2:before {
      -webkit-animation-delay: 0.3s;
      animation-delay: 0.3s;
  }

  .sk-folding-cube .sk-cube3:before {
      -webkit-animation-delay: 0.6s;
      animation-delay: 0.6s;
  }

  .sk-folding-cube .sk-cube4:before {
      -webkit-animation-delay: 0.9s;
      animation-delay: 0.9s;
  }

@-webkit-keyframes sk-foldCubeAngle {
  0%, 10% {
      -webkit-transform: perspective(140px) rotateX(-180deg);
      transform: perspective(140px) rotateX(-180deg);
      opacity: 0;
  }

  25%, 75% {
      -webkit-transform: perspective(140px) rotateX(0deg);
      transform: perspective(140px) rotateX(0deg);
      opacity: 1;
  }

  90%, 100% {
      -webkit-transform: perspective(140px) rotateY(180deg);
      transform: perspective(140px) rotateY(180deg);
      opacity: 0;
  }
}

@keyframes sk-foldCubeAngle {
  0%, 10% {
      -webkit-transform: perspective(140px) rotateX(-180deg);
      transform: perspective(140px) rotateX(-180deg);
      opacity: 0;
  }

  25%, 75% {
      -webkit-transform: perspective(140px) rotateX(0deg);
      transform: perspective(140px) rotateX(0deg);
      opacity: 1;
  }

  90%, 100% {
      -webkit-transform: perspective(140px) rotateY(180deg);
      transform: perspective(140px) rotateY(180deg);
      opacity: 0;
  }
}

div.pleasewait {
  margin-left: auto;
  margin-right: auto;
  padding-top: 5em;
  padding-bottom: 5em;
  text-align: center;
}   
div.pleasewaitwithlogo {
  margin-left: auto;
  margin-right: auto;
  padding-top: 1em;
  text-align: center;
  padding-bottom: 2em;
}

input.readonly {
  background-color: darken($text, 20);
}

.Collapsible__contentInner {
  padding: 10px;
  border: 1px solid #ebebeb;
  border-top: 0; }
  .Collapsible__contentInner p {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 20px; }
    .Collapsible__contentInner p:last-child {
      margin-bottom: 0; }

.Collapsible__trigger {
  display: block;
  font-weight: 400;
  text-decoration: none;
  color: #333333;
  position: relative;
  border: 1px solid white;
  padding: 10px;
  background: #00ac9d;
  color: white; }
  .Collapsible__trigger:after {
    font-family: "Font Awesome 5 Free";
    content: '\f107';
    position: absolute;
    right: 10px;
    top: 10px;
    display: block;
    color:white;
    transition: transform 300ms; }
  .Collapsible__trigger.is-open:after {
    transform: rotateZ(180deg); }
  .Collapsible__trigger.is-disabled {
    opacity: 0.5;
    background-color: grey; }